import React from "react";

function Link(props: {
  name: string;
  url: string;
  onClick: (url: string) => void;
  selected?: boolean;
}) {
  return (
    <div
      className={props.selected === true ? "api-link selected" : "api-link"}
      onClick={() => {
        props.onClick(props.url);
      }}
    >
      {props.name}
    </div>
  );
}

export default Link;
