export function Logo() {
  return (
    <svg
      width="145"
      height="50"
      viewBox="0 0 145 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.4031 11.5934C50.4031 11.5934 46.7932 12.1768 46.7932 16.0429C46.7932 19.9091 46.7932 28.0896 46.7932 28.0896C46.7932 30.4708 46.1775 29.1141 44.974 30.3391H44.3863V15.2836C44.3863 14.618 43.5966 13.1956 42.7912 12.7146C41.9388 13.1956 41.1971 14.611 41.1971 15.2836V30.3391C41.1971 30.3391 41.0981 31.0139 39.7176 31.0139C38.3371 31.0139 38.4182 30.3391 38.4182 30.3391V15.2836C38.4182 14.618 37.6286 13.1956 36.8232 12.7146C35.9708 13.1956 35.2291 14.611 35.2291 15.2836V30.3391H34.6408C33.4186 29.1141 32.8217 30.4708 32.8217 28.0896C32.8217 28.0896 32.8217 19.8903 32.8217 16.0429C32.8217 12.1956 29.2375 11.5934 29.2375 11.5934C28.8359 11.9965 28.6357 13.1575 28.6357 13.7523V28.0896C28.6357 31.6262 29.6212 34.3654 31.5921 36.3086C33.5625 38.2517 36.2917 39.2233 39.7808 39.2233C43.3032 39.2233 46.0506 38.2517 48.0211 36.3086C49.992 34.3654 50.9778 31.6262 50.9778 28.0896V13.7523C50.9787 13.1571 50.786 11.9965 50.4031 11.5934Z"
        fill="white"
      />
      <path
        d="M20.2496 11.5934C20.8421 11.5934 21.3392 11.7951 21.7408 12.1973C22.1415 12.6004 22.3425 13.0993 22.3425 13.6941V36.5917C22.3425 39.5327 21.767 41.9748 20.6158 43.9179C19.4647 45.8611 17.9211 47.3053 15.9851 48.2506C14.0495 49.1959 11.8598 49.6686 9.4184 49.6686C8.05797 49.6686 6.74985 49.5281 5.49407 49.2484C4.23828 48.9679 3.2088 48.6003 2.40692 48.1456C1.74371 47.8305 1.28195 47.4366 1.02033 46.9639C0.758269 46.4913 0.714229 45.9924 0.889079 45.4672C1.13282 44.837 1.49081 44.3989 1.96173 44.1542C2.43265 43.9087 2.93017 43.9087 3.45298 44.1542C4.01067 44.3989 4.82213 44.7403 5.88606 45.1783C6.94956 45.6155 8.1443 45.8348 9.47073 45.8348C11.2838 45.8348 12.8365 45.4842 14.1276 44.7845C15.4178 44.0838 16.4116 43.0597 17.1101 41.7122C17.8078 40.3638 18.1566 38.6924 18.1566 36.6968V32.968L18.784 34.281C18.2259 35.3668 17.4759 36.3121 16.5341 37.1169C15.5927 37.9226 14.5104 38.5528 13.2904 39.0075C12.0686 39.4622 10.7265 39.6903 9.26143 39.6903C7.2731 39.6903 5.58956 39.2609 4.21212 38.4036C2.8338 37.5462 1.78688 36.3554 1.07265 34.8324C0.357114 33.3094 0 31.55 0 29.5544V13.6941C0 13.0993 0.200577 12.6004 0.601731 12.1973C1.00245 11.7956 1.49997 11.5934 2.09341 11.5934C2.72087 11.5934 3.22667 11.7951 3.61039 12.1973C3.9941 12.6004 4.18596 13.0993 4.18596 13.6941V28.6091C4.18596 30.9903 4.7092 32.7842 5.75569 33.9921C6.80218 35.2 8.42424 35.804 10.6219 35.804C12.0167 35.804 13.2817 35.4889 14.4154 34.8586C15.5486 34.2284 16.456 33.3619 17.1363 32.259C17.8165 31.1562 18.1566 29.9399 18.1566 28.6091V13.6941C18.1566 13.0993 18.3476 12.6004 18.7317 12.1973C19.1159 11.7951 19.6217 11.5934 20.2496 11.5934Z"
        fill="white"
      />
      <path
        d="M76.0271 35.2788C76.5839 35.2788 77.0548 35.4626 77.439 35.8302C77.8231 36.1978 78.0146 36.6622 78.0146 37.2219C78.0146 37.7471 77.8231 38.2027 77.439 38.5874C77.0548 38.9729 76.5839 39.1651 76.0271 39.1651H59.0727C58.4452 39.1651 57.956 38.9375 57.608 38.4823C57.2588 38.0276 57.0852 37.6075 57.0852 37.2219C57.1192 36.8722 57.1711 36.5746 57.2417 36.3291C57.3115 36.0845 57.4685 35.804 57.7127 35.4888L72.6775 15.1646L72.7302 15.7423H60.0673C59.5087 15.7423 59.0378 15.5501 58.6545 15.1646C58.2704 14.7799 58.0789 14.3072 58.0789 13.7466C58.0789 13.2214 58.2704 12.775 58.6545 12.4074C59.0378 12.0398 59.5087 11.856 60.0673 11.856H76.1309C76.6886 11.856 77.1512 12.0577 77.5184 12.4599C77.8846 12.8625 78.0678 13.3619 78.0678 13.9567C78.032 14.2022 77.9801 14.4385 77.9104 14.6656C77.8406 14.8937 77.7015 15.1646 77.4922 15.4797L62.6839 35.5414L62.3177 35.2788H76.0271Z"
        fill="white"
      />
      <path
        d="M101.195 35.2788C101.751 35.2788 102.222 35.4626 102.607 35.8302C102.991 36.1978 103.182 36.6622 103.182 37.2219C103.182 37.7471 102.991 38.2027 102.607 38.5874C102.222 38.9729 101.751 39.1651 101.195 39.1651H84.241C83.6136 39.1651 83.1243 38.9375 82.7764 38.4823C82.4271 38.0276 82.2527 37.6075 82.2527 37.2219C82.2876 36.8722 82.3395 36.5746 82.4101 36.3291C82.479 36.0845 82.636 35.804 82.881 35.4888L97.8449 15.1646L97.8977 15.7423H85.2347C84.677 15.7423 84.2061 15.5501 83.822 15.1646C83.4378 14.7799 83.2473 14.3072 83.2473 13.7466C83.2473 13.2214 83.4383 12.775 83.822 12.4074C84.2061 12.0398 84.677 11.856 85.2347 11.856H101.299C101.856 11.856 102.32 12.0577 102.686 12.4599C103.052 12.8625 103.235 13.3619 103.235 13.9567C103.2 14.2022 103.148 14.4385 103.078 14.6656C103.008 14.8937 102.869 15.1646 102.66 15.4797L87.8514 35.5414L87.4851 35.2788H101.195Z"
        fill="white"
      />
      <path
        d="M113.804 37.0119C113.804 37.6075 113.595 38.1147 113.177 38.5349C112.757 38.955 112.252 39.1651 111.659 39.1651C111.066 39.1651 110.578 38.955 110.194 38.5349C109.809 38.1147 109.619 37.6075 109.619 37.0119V2.40283C109.619 1.80806 109.819 1.30871 110.22 0.906076C110.621 0.503879 111.118 0.302124 111.711 0.302124C112.34 0.302124 112.845 0.503879 113.229 0.906076C113.612 1.30871 113.804 1.80806 113.804 2.40283V37.0119H113.804Z"
        fill="white"
      />
      <path
        d="M133.635 39.6903C130.843 39.6903 128.402 39.0955 126.309 37.9047C124.216 36.7147 122.594 35.0779 121.443 32.9943C120.292 30.9115 119.717 28.504 119.717 25.7731C119.717 22.7271 120.318 20.1275 121.521 17.9743C122.726 15.8211 124.286 14.1755 126.204 13.0376C128.122 11.9002 130.164 11.3308 132.327 11.3308C133.966 11.3308 135.526 11.6288 137.01 12.2236C138.491 12.8192 139.817 13.6858 140.986 14.8232C142.155 15.9615 143.087 17.327 143.785 18.9196C144.483 20.5131 144.849 22.2895 144.884 24.2501C144.849 24.8107 144.623 25.2834 144.204 25.6681C143.785 26.0537 143.297 26.2458 142.739 26.2458H122.072L121.13 22.6221H141.274L140.437 23.3573V22.2019C140.332 20.7665 139.87 19.5323 139.05 18.4995C138.23 17.4675 137.227 16.6705 136.042 16.1099C134.855 15.5502 133.617 15.2696 132.327 15.2696C131.28 15.2696 130.242 15.4534 129.213 15.8211C128.184 16.1887 127.26 16.7843 126.44 17.6067C125.62 18.4294 124.966 19.489 124.478 20.784C123.989 22.0794 123.746 23.6379 123.746 25.458C123.746 27.4891 124.155 29.2743 124.974 30.8148C125.794 32.3558 126.936 33.572 128.402 34.4648C129.867 35.3576 131.558 35.804 133.477 35.804C134.733 35.804 135.814 35.6464 136.722 35.3313C137.628 35.0162 138.403 34.6223 139.05 34.1497C139.695 33.677 140.244 33.196 140.698 32.7054C141.151 32.3903 141.605 32.2328 142.059 32.2328C142.547 32.2328 142.965 32.4083 143.314 32.758C143.662 33.1085 143.838 33.5287 143.838 34.0184C143.838 34.614 143.557 35.1392 143 35.5939C141.988 36.6797 140.653 37.6342 138.997 38.4561C137.34 39.278 135.553 39.6903 133.635 39.6903Z"
        fill="white"
      />
    </svg>
  );
}
