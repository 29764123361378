import { ApiConfig } from "../interfaces/config";

const Config: ApiConfig = {
  name: "Yuzzle Api Doc",
  logo: undefined,
  services: [
    {
      name: "User api",
      url: "https://staging.yuzzle.app/user/docs",
    },
    {
      name: "Business api",
      url: "https://staging.yuzzle.app/business/docs",
    },
    {
      name: "Campaign api",
      url: "https://staging.yuzzle.app/campaign/docs",
    },
    {
      name: "Catalog api",
      url: "https://staging.yuzzle.app/catalog/docs",
    },
    {
      name: "Game api",
      url: "https://staging.yuzzle.app/game/docs",
    },
    {
      name: "Order api",
      url: "https://staging.yuzzle.app/order/docs",
    },
    {
      name: "Payment api",
      url: "https://staging.yuzzle.app/payment/docs",
    },
    {
      name: "Player api",
      url: "https://staging.yuzzle.app/player/docs",
    },
    {
      name: "Prize api",
      url: "https://staging.yuzzle.app/prize/docs",
    },
    {
      name: "Docs api",
      url: "https://staging.yuzzle.app/document/docs",
    },
  ],
};
export default Config;
