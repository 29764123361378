import React from "react";
import { ApiService } from "../interfaces/config";
import Link from "./Link";

import { Logo } from "../Logo";

function Sidebar(props: {
  name: string;
  logo?: string;
  services: ApiService[];
  selectedUrl?: string;
  onSelect: (url: string) => void;
}) {
  return (
    <div className="side-bar">
      <div className="side-bar-header">
        <Logo />
        <h4>{props.name}</h4>
        <div></div>
      </div>
      <div className="side-bar-body">
        {props.services &&
          props.services.map((s, idx) => {
            return (
              <Link
                key={idx}
                name={s.name}
                url={s.url}
                selected={props.selectedUrl === s.url}
                onClick={props.onSelect}
              />
            );
          })}
      </div>
    </div>
  );
}

export default Sidebar;
