import development from "./development";
import staging from "./staging";
import blank from "./blank";

let env: any;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "development":
    env = development;
    break;
  case "staging":
    env = staging;
    break;
  default:
    env = blank;
    break;
}

export default env;
