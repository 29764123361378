import React, { useState, useEffect } from "react";

import SwaggerUI from "swagger-ui";
import "../node_modules/swagger-ui/dist/swagger-ui.css";

import Sidebar from "./components/Sidebar";
import "./App.css";
import api from "./environment";

function App() {
  // active swagger documentation state
  const [activeSwagger, setActiveSwagger] = useState(api.services[0].url);

  // initiate swagger after component mounts
  useEffect(() => {
    SwaggerUI({
      domNode: document.getElementById("api-data"),
      url: activeSwagger,
    });
  });

  // render initial dom
  return (
    <div className="App">
      <Sidebar
        name={api.name}
        logo={api.logo}
        services={api.services}
        selectedUrl={activeSwagger}
        onSelect={(url) => {
          setActiveSwagger(url);
        }}
      />

      <div id="api-data" />
    </div>
  );
}

export default App;
